.contAdminPC{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    height: 100%;
}


.adminHome{
    height: 91%;
}

.adminCont{
    height: 100%;
}

.linkAdPC{
    padding: 10px;
    background-color: #176b69;
    border-radius: 25px;
    margin: 10px;
    cursor: pointer;
}