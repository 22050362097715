.contentBrands{
    background-color: #fff;
    padding-bottom: 50px;
	padding-top: 120px;
}
.contentLogos{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 120px;
    padding-bottom: 80px;
}

.imgMisvis{
    padding-left: 15px;
    padding-right: 15px;
}

.linkPrdsContainer{
    text-align: -webkit-center;
}

.prodsLink{
    background-color: #176b69;
    width: fit-content;
    color: #fff;
    font-weight: 700;
    padding: 20px 50px 20px  50px;
    border-radius: 10px;
}

a{
    text-decoration: none;
}



/*  sdfgdfgsfgsfdgsdffgsdfg */


@mixin white-gradient {
	background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
}



@keyframes scroll {
	0% { transform: translateX(0); }
	100% { transform: translateX(calc(-250px * 7))}
}


.slider {
	background: white;
	/* box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125);*/
	height: 100px;
	margin: auto;
	overflow:hidden;
	position: relative;
	width: 100%;
	margin-bottom: 110px;
	
	&::before,
	&::after {
		@include white-gradient;
		content: "";
		height: 100px;
		position: absolute;
		width: 200px;
		z-index: 2;
	}
	
	&::after {
		right: 0;
		top: 0;
		transform: rotateZ(180deg);
	}

	&::before {
		left: 0;
		top: 0;
	}
	
	.slide-track {
		animation: scroll 40s linear infinite;
		display: flex;
		width: calc(250px * 14);
	}
	
	.slide {
		height: 100px;
		width: 250px;
	}
}
