.containerPlp{
    background-color: #fff !important;
}

.titPLP{
    color:#000 !important;
    margin-top: 35px;
    text-align: center;
}

.ulPlp{
    text-align: left;
    max-width: fit-content;
}

.liCat{
    text-transform: capitalize !important;
    background-color: #E6E6E6;
    color: #6A6A6A;
    margin-top: 0;
    padding: 5px 20px 5px 20px;
    border-bottom: 2px solid #CDCDCD;
    font-weight: 700;
}

.liCat:hover{
    background-color: #639A98;
    color: #316A69;
}

.itemProd{
    max-width: 180px !important;
    height: 290px;
    background-color: #F6F6F6;
    padding-left: 3px;
    padding-right: 3px;
    margin-bottom: 15px;
    text-align: center;
    margin-top: 5px!important;
    margin-left: 12px !important;
    margin-right: 12px !important;
    margin-bottom: 5px !important;
}

.imgProds{
    max-height: 156px;
    padding: 5%;
}

.descProd{
    text-align: center;
    color: #000 !important;
    font-size: 14px;
}

.selectMob{
    margin-top: 10% !important;
    margin-bottom: 10% !important;
}

.ordenar{
    color: #000 !important;
    text-align: right;
}

.selectOrdenar{
    margin-bottom: 0 !important;
    margin-left: 15px !important;
}
.contadorPags{
    color: #000 !important; 
    width: fit-content;
}

.btnSiguiente{
    background-color: #316A69;
    width: fit-content;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 5px;
}


.contPagination{
    padding: 0 !important;
}

.pagination{
    width: 100%;
    padding: 0 !important;
}

.colBtn{
    padding: 0 !important;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.rowpags{
    margin-top: 25px !important;
    margin-bottom: 25px;
}

.iconStyle{
    margin-top: 0 !important;
}

.selectProds select{
    margin-top: 5px !important;
}

.inputLabel{
    color: #6A6A6A !important;
}

.hbuscador{
    margin-bottom: 15px;
}

.paginationSt{
    align-items: center;
}

.searchBarSt {
    margin-top: 8px;
}

@media (max-width:438px){
    .itemProd{
        margin-left: 1px !important;
        margin-right: 1px !important;
    }

}