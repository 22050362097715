.divContainer{
    background-image: url('https://productosbredy.com/deliveryBg.jpg');
    background-size: cover;
}

.deliveryImageContainer{
    padding: 80px 80px 80px 80px;
}

.imagePadding{
    background-color: #176b69;
    padding:  30px 50px 30px 50px;
    border-radius: 25px;
}

.imgMobDel{
    padding: 5%;
}