.uneteContainer{
    background-image: url('https://productosbredy.com/carreers.png');
    background-size: cover;
    background-repeat: no-repeat;
    padding: 150px 120px 150px 120px !important;
}

@media (max-width: 699px){
    .uneteContainer{
        padding: 120px 50px 120px 50px  !important;
    }
}

.h1Join{
    color: #176b69 !important;
    text-align: center;
}

.pJoin{
    text-align: center;
    color: #000 !important;
}

.formCol{
    display: flex;
}