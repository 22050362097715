.contGralCats{
    height: 100%;
    padding-top: 50px !important;
    padding-bottom: 50px !important;
}

.itemCats{
    background-color: #DFDFDF;
    border-radius: 15px;
}

.contGralCats{
    background-color: #ffffff;
}

.divCatList{
    max-width: 500px;
    min-width: 400px;
}


.colLis{
    display: flex;
    justify-content: center;
}

.titModalEdit{
    background-color: #176b69;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
}

.catEditTitSts{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.titCatEdit{
    color: #000 !important;
    height: fit-content;
    background-color: #DFDFDF;
    padding: 10px 15px;
    border-radius: 10px;
}

.divInputsEdit{
    display: grid;
}

.titCatEditAct{
   color: #20c997 !important;
}

.titCatEditInAct{
    color: grey !important;
 }

 .rowContEdit{
    margin-top: 25px !important;
 }

 .btnEdit{
    width: fit-content !important;
    margin-top: 15px !important;
    border-radius: 15px !important;
    padding: 0 25px !important;
 }

 .colContForm {
    padding-right: 25px !important;
 }

.colContForm label {
  color: black !important;
}

 .divBtnEdit{
    text-align: right;
}

.inputEdit{
    min-height: 40px;
    border: 1px solid #fff;
    background-color: #f7f7f7;
}