.contTable {
    padding: 15px 20px;
    background-color: #fff;
}

.pagAdminPrdos p {
    color: black !important;
}

.headContAdProds {
    margin-top: 0;
    margin-bottom: 10px;
}

.filtroLabel {
    color: black !important;
    padding-right: 25px !important;
}
