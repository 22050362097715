.containerAlign{
    background-color: #176b69;
}

.itemNav{
    text-decoration: none;
    color: #fff;
    padding-left: 10px;
    padding-right: 10px;
    font-weight: 500;
    font-size: 18px;
    border: 1px solid #176b69;
    border-radius: 5px;
    padding:4px 7px;
}

.itemNav:hover{
    border: 1px solid #fff;
    border-radius: 25px;
    padding:4px 7px;
}

.hStyle{
    align-items: center;
    justify-content: center;
}

.imgH{
    margin-top: 5px;
    margin-bottom: 5px;
    padding-left: 50px;
}

.colImg{
    text-align: left;
}

.colNav{
    text-align: right;
}

.menuStyle{
    text-align: right;
    padding-right: 10% !important;
}

.desplegableMenu{
    width: fit-content;
}

ul{
    list-style: none;
    text-align: right;
    padding-right: 17px;
}

li{
    margin-top: 5px;
}

.navMob{
    max-width: fit-content !important;
    position: absolute;
    right: 0;
    top: 65px;
    background-color: #176b69;
    z-index: 4;
}

.menuHide{
    display: none;
}

.menuShow{
    display: block;
}

.linkActivo{
    border: 1px solid #fff;
    background-color: #fff;
    color: #176b69;
    border-radius: 25px;
    padding: 4px 7px;
}

.whatsapp{
    width: 50px;
}

.whatsLink{
    position: fixed;
    right: 0;
    top: 40%;
    width: fit-content !important;
}

.contNavFlag{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.hide{
    display: none !important
}

.avatNom{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 35px !important;
}

.txtUser{
    padding-left: 5px;
}

.colTitAd{
    display: flex;
    justify-content: center;
    align-items: center;
}