.whatsapp-floating-icon-link {
  position: fixed;
  bottom: 10%;
  right: 10px;
  background-color: #fff;
  color: white;
  padding: 1px 1px !important;
  border-radius: 50%;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  width: fit-content !important;
}

.whatsapp-floating-icon {
  font-size: 60px !important;
  color: #25d366;
}