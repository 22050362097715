.imgContact{
    max-width: 100%;
}


.colText{
    text-align: center;
    padding: 5% !important;
}

.formCol{
    padding: 5% !important;
    display: flex;
    align-items: center;
}

.rowTit{
    text-align: left;
}

.subTit{
    margin-top: 45px;
}

label, p, h1, h3{
    color: #fff !important;
}

label{
    font-weight: 700;
    font-size: 18px;
    margin-top: 10px;
}



.formSt{
    display: grid;
    border:3px solid #fff;
    padding: 5%;
    border-radius: 5%;
    max-width: 400px;
    min-width: 380px;
}

.logoContactUs{
    max-width: 450px !important;
}

.brandBredy, strong{
    color: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
}

.follow img{
    width: 25px;
    padding-left: 5px;
    padding-right: 5px;
}


.btnContainer{
    text-align: right;
    margin-top: 25px;
}

.linkSendMail{
    text-align: center;
    background-color: #fff;
    padding: 10px 25px 10px 25px;
    color: black;
    font-weight: 700;
    border-radius: 25px;
}

.ventaTiendas{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px !important;
    margin-bottom: 10px;
    text-align: center;
}

.colSucs{
    text-align: center;
    margin-top: 15px !important;
}

.colDistArt{
    padding-left: 5% !important;
    margin-top: 15px !important;
    text-align: center;
}

.imgMxFlag{
    width: 50px;
}


.imgUsFlag{
    width: 45px;
}

.imgUsFlag, .imgMxFlag{
    padding: 5px;
    cursor: pointer;
    height: auto;
}


.labCheck{
    display: flex;
}

.pPol, .aLinkPol{
    font-size: 14px;
    font-weight: 400;
}

.pPol{
    margin-left: 5px;
}

a .aLinkPol{
    color: #fff !important;

}