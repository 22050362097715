@import url('https://fonts.googleapis.com/css2?family=Cardo:ital,wght@0,400;0,700;1,400&display=swap');

.contactLayout{
    background-image: url('https://productosbredy.com/tazaBg.png');
    background-size: cover;
}

.contactLinkContent{
    width: 35%;
    padding: 150px 150px;
}

.contactLinkText{
    font-family: "Cardo";
    font-size: 31px;
    color: #fff;
}

.linkContainer{
    text-align: -webkit-center;
}


.contactLink{
    background-color: #176b69;
    width: fit-content;
    color: #fff;
    font-weight: 700;
    padding: 20px 50px 20px  50px;
    border-radius: 10px;
    margin-top: 150px;
}

a{
    text-decoration: none !important;
}

p{
    margin: 0;
}