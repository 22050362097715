.usLayout{
    margin-top: 45px;
    padding-bottom:  50px;
    background-color: #176b69;
    color: #fff;
    border-bottom: 1px solid #000;
}

.titleUs{
    text-align: left;
    padding-left: 5%;
}

.usContent{
    display: flex;
}

.usImage{
    text-align: left;
    padding-left: 5%;
    display: flex;
    justify-content: center;
}

.usText{
    text-align: left;
    padding-right: 5%;
    padding-left: 2%;
}

img{
    max-width: 100%;
}

.usImageMisvis{
    text-align: center;
    padding-left: 5%;
}

h1{
    font-size: 45px;
}

h3{
    font-size: 18px;
}

.misiVisiText{
    text-align: left;
    padding-right: 0 !important;
    padding-left: 5% !important;
}

@media (max-width: 991px){
    .misiVisiText{
        padding-right: 5% !important;
        padding-left: 5% !important;
    }

    .sVision, .sMision{
        border-radius: 15px 15px 15px 15px !important;
    }

    .sVision{
        margin-top: 25px !important;
    }
}

.sVision, .sMision{
    background-color: #E6E6E6;
    border-radius: 15px 0 0 15px;
    padding: 3% 5% 3% 5% !important;
    height: fit-content;
}

.sVision h1, .sVision h3, .sMision h1, .sMision h3, .sMision strong, .sVision strong{
    color: #968B8f !important;
}

.contentText{
    height: 100%;
}

.sVision h3, .sMision h3{
    font-size: 18px;
}

.misvis{
    max-width: 323px;
}

