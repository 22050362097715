html,body,.altoLogin,#root,.contLogin{
    height: 100% ;
}

.logoLog{
    max-width:80%;
}

.formLog{
    display: grid;
    max-width: 80%;
    min-width: 80%;
}

.colImgLog{
    text-align: center;
}

.colFormLog{
    display: flex;
    justify-content: center;
    align-items: center;
}


@media (max-width: 480px){
    .altoLogin{
        height: auto !important;
        margin-top: 35%;
    }
}

.inputLogin{
    border-radius: 15px ;
    padding: 5px;
}

.btnLogin{
    margin-top: 15px;
    width: fit-content;
    border-radius: 20px;
    padding: 5px 10px;
}

.btnCont{
    text-align: right;
}