.footerContainer{
    margin-top: 35px !important;
    margin-bottom: 35px;
}

.col3{
    text-align: center;
}

.col1, .col2{
    color: #fff;
    padding-left: 5%;
    text-align: center;
}

.follow{
    display: flex;
    align-items: center;
    border-top: 3px solid #fff;
    border-bottom: 3px solid #fff;
    padding-left: 5%;
    color: #fff;
    font-weight: 700;
    padding-top: 15px;
    padding-bottom: 15px;
}

.lang{
    border-top: 3px solid #fff;
    border-bottom: 3px solid #fff;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.followText{
    padding-right: 15px;
}

.brandBredy, strong{
    color: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
}

.follow img{
    width: 25px;
    padding-left: 5px;
    padding-right: 5px;
}

p{
    margin-bottom: 0 !important;
}

footer{
    background-color: #176b69;
}

.imgMxFlag{
    width: 50px;
}


.imgUsFlag{
    width: 45px;
}

.imgUsFlag, .imgMxFlag{
    padding: 5px;
    cursor: pointer;
    height: auto;
}

.telFooter{
    text-decoration: none;
    color: #fff;
}

.logofoo{
    max-width: 260px;
}