.flyerContainer{
    background-color: #fff;
    text-align: center;
}

.titleFlyer{
    color: #176b69 !important;
    margin-top: 50px !important;
    margin-bottom: 50px;
}

.imgFlyer{
    padding: 0% 10% !important;
    margin-bottom: 5%;
}

.flyerIframe{
    border: 1px solid lightgray;
}

.containerFlayer{
    padding-bottom: 50px;
}


